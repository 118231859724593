import React from 'react';
import './qrCode.scss';

import qrCode from '../images/Tukan (1).svg';

const QrCode = () => {
    return (
        <div
            data-sal="slide-up"
            data-sal-duration="2000"
            data-sal-delay="100"
            data-sal-easing="ease"
            className="qr-container"
        >
            <div className="download-box">
                <h3>Download the App</h3>
                <span>Available on ios</span>
                <img src={qrCode} />
            </div>
        </div>
    );
};

export default QrCode;
