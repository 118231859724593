import React from 'react';
import image from '../images/logo-white.png';

import Banner from '../components/banner';
import Description from '../components/description';
import QrCode from '../components/qrCode';
import Footer from '../components/footer';
import './reset.scss';
import './global.scss';
import _ from 'lodash';

const IndexPage = () => {
    return (
        <>
            <div className="header">
                <div className="home-nav">
                    <img src={image} />
                    <h1>Tukan</h1>
                    <a href="https://apps.apple.com/us/app/1489419899">
                        Download for iOS
                    </a>
                </div>
            </div>
            <div className="background">
                <Banner />
                <Description />
                <QrCode />
                <Footer />
            </div>
        </>
    );
};

export default IndexPage;
