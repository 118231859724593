import React from 'react';
import { IoMdJournal } from '@react-icons/all-files/io/IoMdJournal';
import { RiGitRepositoryPrivateFill } from '@react-icons/all-files/ri/RiGitRepositoryPrivateFill';
import { FaThList } from '@react-icons/all-files/fa/FaThList';

import './description.scss';

import iphone1 from '../images/iPhone 12 Pro1.png';
import iphone2 from '../images/iPhone 12 Pro.png';
import iphone3 from '../images/checklist.png';
import iphone4 from '../images/passengers.png';

const Description = () => {
    return (
        <div className="description">
            <Body />
            <div className="row">
                <Box
                    title="Keep track of your bookings, reservations, and more."
                    textStyles={[
                        {
                            word: 'bookings,',
                            color: '#8aea92'
                        },
                        {
                            word: 'reservations,',
                            color: '#75DDDD'
                        },
                        {
                            word: 'more.',
                            color: '#FF729F'
                        }
                    ]}
                    image={iphone1}
                />
                <Box
                    title="Flex your trips and check others’ out for inspiration."
                    textStyles={[
                        {
                            word: 'trips',
                            color: '#8aea92'
                        },
                        {
                            word: 'inspiration.',
                            color: '#75DDDD'
                        }
                    ]}
                    image={iphone2}
                />
            </div>
            <div
                data-sal="slide-up"
                data-sal-duration="2000"
                data-sal-delay="300"
                data-sal-easing="ease"
                className="features"
            >
                <Feature title="All your travel info in one place.">
                    <IoMdJournal size={50} color="#8aea92" />
                </Feature>
                <Feature title="Your travels stay private till you’re back.">
                    <RiGitRepositoryPrivateFill size={50} color="#75DDDD" />
                </Feature>
                <Feature title="No plans? Make wishlists till you book.">
                    <FaThList size={50} color="#FF729F" />
                </Feature>
            </div>
            <div className="row">
                <Box
                    title="Never suffer from “am I forgetting something?” again."
                    textStyles={[
                        {
                            word: 'forgetting',
                            color: '#8aea92'
                        }
                    ]}
                    image={iphone3}
                />
                <Box
                    title="Collaborate and coordinate with the whole crew."
                    textStyles={[
                        {
                            word: 'Collaborate',
                            color: '#8aea92'
                        },
                        {
                            word: 'coordinate',
                            color: '#75DDDD'
                        }
                    ]}
                    image={iphone4}
                />
            </div>

            <div className="row">
                <Box
                    title="Made by real people, for real people."
                    textStyles={[
                        {
                            word: 'Made',
                            color: '#8aea92'
                        },
                        {
                            word: 'for',
                            color: '#75DDDD'
                        }
                    ]}
                />
                <Box
                    title="Taking the “trap” out of tourist-."
                    textStyles={[
                        {
                            word: '“trap”',
                            color: '#8aea92'
                        }
                    ]}
                />
            </div>
        </div>
    );
};

const Body = () => {
    return (
        <div
            data-sal="slide-up"
            data-sal-duration="2000"
            data-sal-delay="300"
            data-sal-easing="ease"
            id="body"
            className="body-container"
        >
            <h2 className="body">
                Planning a vacation should be exciting, not overwhelming. That’s
                why we created a one-stop spot for all
            </h2>
        </div>
    );
};

const Box = ({ title, textStyles, image }) => {
    const styleText = (sentence) => {
        const splitTitle = sentence.split(' ');

        return splitTitle.map((word, i) => {
            const style = textStyles.find((textWord) => textWord.word === word);

            if (style) {
                return (
                    <span key={i} style={{ color: style.color }}>
                        {word}{' '}
                    </span>
                );
            }

            return `${word} `;
        });
    };

    return (
        <div
            data-sal="slide-up"
            data-sal-duration="2000"
            data-sal-delay="100"
            data-sal-easing="ease"
            className={image ? 'box' : 'textBox'}
        >
            <h4 className="title">{styleText(title)}</h4>
            {image && <img src={image} />}
        </div>
    );
};

const Feature = ({ title, children }) => {
    return (
        <div className="feature">
            {children}
            <h5>{title}</h5>
        </div>
    );
};

export default Description;
