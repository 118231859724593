import React from 'react';
import './banner.scss';

import phoneImage from '../images/group2.png';
import appStoreImage from '../images/app-store-white.png';

const Banner = () => {
    return (
        <>
            <div id="banner1" className="banner">
                <div className="container">
                    <div
                        data-sal="slide-right"
                        data-sal-duration="2000"
                        data-sal-delay="100"
                        data-sal-easing="ease"
                        className="left"
                    >
                        <h1 className="title">Bon voyage, messy planning.</h1>
                        <p className="body">
                            Don’t let unorganized planning kill your vacation
                            vibe. From itineraries to inspiration, checklists to
                            check-ins, with Tukan you can plan it all.
                        </p>
                        <a href="https://apps.apple.com/us/app/1489419899">
                            <img src={appStoreImage} />
                        </a>
                    </div>
                    <div
                        data-sal="slide-left"
                        data-sal-duration="2000"
                        data-sal-delay="100"
                        data-sal-easing="ease"
                        className="right"
                    >
                        <img src={phoneImage} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Banner;
